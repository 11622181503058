<!-- 客户销售表-详情 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20">
      <!-- 产品编号 -->
      <!-- <FormItem>
        <span>产品编号：</span>
        <Select placeholder="请输入" v-model="queryFrom.dept_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in thirdList" :value="item.dept_id" :key="index">{{ item.dept_name }}</Option>
        </Select>
      </FormItem> -->
      <!-- 产品名称 -->
      <FormItem>
        <span>产品名称：</span>
        <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" placeholder="请选择" v-model="queryFrom.product_id" clearable filterable>
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 规格型号 -->
      <FormItem>
        <span>规格型号/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_code_id" :disabled="!queryFrom.product_id" multiple clearable filterable class="iviewIptWidth250 marginRight60" :max-tag-count="queryFrom.product_code_id.length == 1 ? 1 : 0">
          <Option v-for="item in modelList" :value="item.code_id" :key="item.model_name + item.item_number" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 出库单号 -->
      <FormItem>
        <span>出库单号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.order_number"></i-input>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0 marginLeft20" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
        <span class="pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="back">返回</span>
      </FormItem>
    </Form>
    <div class="tabDiv" ref="tabDiv">
      <Table :height="tableHeight" :productList="listColumns" :productData="listData" :option_page="option_page" border show-summary :summary-method="handleSummary" class="table" :loading="loading" :isLoad="loading"></Table>
    </div>
    <template v-if="listData.length != 0">
      <div class="marginTop20">
        <Page v-if="total <= 60" :total="total" :current="queryFrom.page" @on-change="changePage" class="fl page" />
        <Page v-if="total > 60" show-elevator :total="total" :current="queryFrom.page" @on-change="changePage" class="fl page" />
        <div class="fr totalFont page">共计{{ total }}条记录</div>
      </div>
    </template>
    <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData.length == 0 && !loading">
      <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
    </div>
    <!-- 二级详情弹窗 -->
    <Modal v-model="popup" :closable="false" footer-hide width="1000">
      <Table :columns="popupListColumns" :data="popupListData" border show-summary :summary-method="handleSummary" class="table"></Table>
    </Modal>

    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'detailsCustomerSalesTable',
  components: {
    Table,
    TableSetup,
  },
  data() {
    return {
      Loagin: false,
      loading: true,
      tableHeight: 0,
      outOrderList: [],
      titleList: [],
      option_page: '26',
      setupStatus: false,
      // 二级详情弹窗
      total: 0,
      popup: false,
      popupListData: [],
      popupListColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
          key: 'index',
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'a',
          align: 'center',
        },
        {
          title: '货号/SKU',
          key: 'b',
          align: 'center',
        },
        {
          title: '批号',
          key: 'c',
          align: 'center',
        },
        {
          title: '销售订单号',
          key: 'd',
          align: 'center',
        },
        {
          title: '销售数量',
          key: 'e',
          align: 'center',
        },
        {
          title: '销售单价',
          key: 'f',
          align: 'center',
        },
      ],

      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          key: 'index',
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '基本单位',
          key: 'product_unit',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '规格型号',
          key: 'product_model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '货号/SKU',
          key: 'product_item_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '厂商',
          key: 'factory_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '销售数量',
          key: 'sale_count',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '销售金额',
          key: 'sale_price',
          align: 'center',
          minWidth: 80,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableBlakcFont',
                },
                '¥' + param.row.sale_price
              ),
            ])
          },
        },
        // {
        //   title: '操作',
        //   align: 'center',
        //   width: 189,
        //   render: (h, param) => {
        //     return h('div', [
        //       h(
        //         'span',
        //         {
        //           style: {
        //             color: '#389AFC',
        //             fontSize: '14px',
        //             cursor: 'pointer',
        //           },
        //           on: {
        //             click: () => {
        //               this.popup = true
        //             },
        //           },
        //         },
        //         '详情'
        //       ),
        //     ])
        //   },
        // },
      ],
      // 表单拉下选项
      thirdList: [],
      // 规格下拉
      modelList: [],
      // 查询参数
      queryFrom: {
        product_id: '',
        product_code_id: '',
      },
      pages: {
        page: 1,
        rows: 10,
      },
      // 产品下拉
      productList: [],
      customer_id: '',
      customer_type: '',
      totalMoney: 0,
      totalAmount: 0,
    }
  },
  created() {
    this.queryOption()
    this.getstatementSaleDetail()
    // this.getproductShow()
    this.queryProduct()
  },
  methods: {
    // 导出
    Export() {
      if (this.Loagin) {
        return
      }
      this.Loagin = true
      let data = {
        customer_id: this.$route.query.id,
        customer_type: this.$route.query.type,
        product_id: this.queryFrom.product_id,
        product_code_id: this.queryFrom.product_code_id,
        sale_begin_date: this.$route.query.sale_begin_date,
        sale_end_date: this.$route.query.sale_end_date,
      }
      this.$http.downFile(this.$api.getStatementSaleDetailExport, data, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '客户销售表详情表.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          // this.queryStoreList()
        })
    },
    // 页码改变
    changePage(e) {
      this.pages.page = e
      this.getstatementSaleDetail()
    },
    back() {
      this.$router.go(-1)
    },
    // 点击查询
    query() {
      this.getstatementSaleDetail()
    },
    // 处理金额
    OpearateMoney(item) {
      let num = item
      num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
      var valueArr = num.split('.')[0].split('').reverse()
      const valueFloat = num.split('.')[1]
      let valueString = ''
      for (let i = 0; i < valueArr.length; i++) {
        valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
      }
      const money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      return '¥' + money
    },
    // 获取列表
    getstatementSaleDetail() {
      let data = {
        page: this.pages.page,
        rows: this.pages.rows,
        customer_id: this.$route.query.id,
        customer_type: this.$route.query.type,
        product_id: this.queryFrom.product_id,
        product_code_id: this.queryFrom.product_code_id,
        sale_begin_date: this.$route.query.sale_begin_date,
        sale_end_date: this.$route.query.sale_end_date,
      }
      this.loading = true
      this.$http.get(this.$api.statementSaleDetail, data, true).then(res => {
        console.log(res)
        this.listData = res.data
        this.totalAmount = 0
        this.totalMoney = 0
        this.loading = false
        this.listData.forEach((item, index) => {
          this.totalAmount = this.totalAmount + item.sale_count
          this.totalMoney = item.sale_price.replace(/,/g, '') * 1 + this.totalMoney
          item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
          item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : ''
          // 判断是录入的是年月还是年月日
          item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date) : ''
          item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : ''
          item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period) : ''
          item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
          item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
          if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.business_license_valid_period = '长期'
          } else {
            item.business_license_valid_period = ''
          }
          if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
            item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
          } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
            item.record_valid_period = '长期'
          } else {
            item.record_valid_period = ''
          }
          item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
          item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
          item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
          item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
          item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
          item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
          item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
          item.sale_price = '¥' + item.sale_price
        })
        this.$nextTick(() => {
          this.tableHeight = this.$refs.tabDiv.offsetHeight - 50 + ''
          this.$forceUpdate()
        })
        this.totalMoney = this.OpearateMoney(this.totalMoney)
        this.total = res.total
      })
    },

    // 查询产品
    // 如果id为空 就发送请求 获取产品名称id
    queryProduct(id = null) {
      this.$http.get(this.$api.productShow, { product_id: id, search_type: '3' }, false).then(res => {
        if (!id) {
          this.productList = res.data
        } else {
          this.modelList = res.data
        }
      })
    },
    // 产品名称改变
    productChange(e) {
      // e不存在就不执行
      if (!e) return
      this.modelList = []
      this.queryProduct(e)
    },
    strInsert(str, length) {
      let reg = new RegExp('\\d{1,' + length + '}', 'g')
      console.log(str)
      console.log(reg)
      let ma = str.match(reg)
      return ma.join(',') + ','
    },
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        if (key === 'sale_price') {
          sums[key] = {
            key,
            value: this.totalMoney,
          }
        } else if (key === 'sale_count') {
          sums[key] = {
            key,
            value: this.totalAmount,
          }
        } else {
          sums[key] = {
            key,
            value: '',
          }
        }
      })

      return sums
    },
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
.marginTop20 {
  margin-top: 20px;
}
.marginRight60 {
  margin-right: 60px;
}
</style>
